/* .column {
  float: left;
  width: 50%;
  padding-right: 10px;
  padding-left: 10px;
} */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.mylink {
  color: white !important;
}

.inline {
  position: relative;
  text-align: center;
}

.cfpspinnertext {
  color: black ;
  font-size: 10px;
}
.synchspinnertext {
  color:black ;
  font-size: 20px;
}
.synchoverlay {
  top:1200px;
  left:0px;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 10;
}

.myborder {
  border: 5px solid lightgrey;
  border-radius: 10px;
  padding-left: 5px;
  padding-right: 5px;
  /*border-color: black;*/
  
}
.centered {
  position: absolute;
  top: 5%;
  left: 5%;
  right: 5%;
  
}
.box {
  background-color: #72bb53 ;
  padding: 10px ;
  border: 1px solid #72bb53 ;
  border-radius: 4px;
}
.btnwide {
  width: 100%;
  color: white;
  font-weight: bold;
  background-color: #053178;
}
.btnshort {
  width: 40%;
  color: white;
  font-weight: bold;
  background-color: #053178;
}
.imgwide {
  width: 70%;
  height:50px;
}
.imgshort {
  
  height:55px;
}
.btnwide:disabled {
  background-color: #053178;
  color: white;
}

.alignleft {
  text-align: left;
}
.alert-fixed {
  position:fixed; 
  top: 0px; 
  left: 0px; 
  width: 100%;
  z-index:9999; 
  border-radius:0px
}